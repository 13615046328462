* {
  box-sizing: border-box;
  font-size: 16pt;
}

body {
  margin: 10px auto;
  width: 100vw;
  max-width: 820px;
}

#summary-background {
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  background-color: black;
  background-color: rgba(0,0,0,0.4);
  padding: 10vh 10vw;
  z-index: 1;
  visibility: hidden;
}

#summary-box {
  background-color: white;
  width: 100%;
  max-height: 100%;
  max-width: 700px;
  margin: 0px auto;
  padding: 5vw;
  overflow-y: auto;
}

#summary-box h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.summary-item {
  margin: 0px 0px 0px 5%;
}

.summary-indent {
  margin: 0px 0px 0px 10%;
}

.summary-double-indent {
  margin: 0px 0px 0px 15%;
}

#summary-buttons {
  display: flex;
}

.summary-button {
  min-width: 30%;
}

span {
  margin-top: auto;
}

input {
  font-size: 14pt;
}

header {
  text-align: center;
  margin: 20px 20px;
}

.redder {
  color: red;
}

h1 {
  font-size: 20pt;
}

h1, h2, h4{
  margin: 0;
}

.cat-name {
  color: red;
  font-size: 16pt;
  margin-top: 8px;
  margin-left: 8px;
}

.main-grid {
  display: grid;
  grid-template-columns: calc(60vw - 20px) repeat(4, 10vw);
  column-gap: 5px;
}

.grid-banner {
  grid-column-start: 1;
  grid-column-end: -1;
  margin: 0px;
  background-color: silver;
  text-align: center;
}

#grid-heading-spacer {
  grid-column-start: 1;
  grid-column-end: 3;
}

.grid-heading {
  margin: auto 0px;
  text-align: center;
}

.at-Left {
  grid-column-start: 1;
}

.indented-item {
  padding-left: 20px;
}

.loose-item {
  padding-left: 8px;
}

.unit {
  text-align: right;
}

.quant-input {
  margin: 0px;
  vertical-align: bottom;
  border-bottom: none;
  padding-left: 2px;
  background-color: silver;
}

.center-text {
  text-align: center;
}

hr {
  grid-column-start: 1;
  grid-column-end: -1;
  width: 100%;
  margin: 0px;
}

.contact-row {
  display: flex;
  justify-content: space-around;
}

.contact-row input {
  width: 40%;
  height: 3vh;
  min-height: 25px;
  margin: 2px;
}

#total-spacer {
  grid-column-start: 1;
  grid-column-end: -1;
  text-align: center;
}

#total-spacer, #total-label, #total-counter {
  font-size: 20pt;
}

.button-group {
  display: flex;
  align-items: center;
  margin: 20px 0px;
  position: sticky;
  bottom: 0px;
  background-color: white;
}

button {
  display: block;
  margin: 10px auto;
  min-width: fit-content;
}

#final-spacer {
  width: 20px;
}

#loading-box {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 47vh;
  left: 40vw;
  height: 6vh;
  width: 20vw;
  min-width: 200px;
  min-height: 50px;
  text-align: center;
  background-color: silver;
  border: 1px solid black;
  z-index: 1;
}

#loading-box p {
  margin: 0px;
}

@media (min-width: 820px) {
  .main-grid {
    grid-template-columns: 480px repeat(4, 80px);
  }
}

@media (max-width: 700px) {
  #summary-background {
    padding: 0px;
  }
  #summary-buttons {
    flex-direction: column;
  }
  .summary-button {
    min-width: 80%;
  }
  .main-grid {
    grid-template-columns: repeat(2, 15vw) repeat(2, calc(35vw - 7.5px));
  }
  .indented-item, .loose-item, .at-Left {
    grid-column-start: 1;
    grid-column-end: -1;
  }
  .cat-name, .indented-item, .loose-item {
    text-align: center;
    margin-left: 0px;
    padding-left: 0px;
  }
  .contact-row {
    flex-wrap: wrap;
  }
  .contact-row input {
    width: 100vw;
  }
  #grid-heading-spacer {
    grid-column-end: 1;
  }
  input span, .grid-heading, h2 {
    text-align: center;
  }
  #total-label, #total-counter {
    margin-top: auto;
    margin-bottom: auto;
  }
}